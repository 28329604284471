export default {
  methods: {
    DataURIToBlob(dataURI) {
      const splitDataURI = dataURI.split(",");
      const byteString =
        splitDataURI[0].indexOf("base64") >= 0
          ? atob(splitDataURI[1])
          : decodeURI(splitDataURI[1]);
      const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

      const ia = new Uint8Array(byteString.length);
      for (let i = 0; i < byteString.length; i++)
        ia[i] = byteString.charCodeAt(i);

      return new Blob([ia], { type: mimeString });
    },
    checkListResposnseFormData(
      items,
      check_list_id,
      bike_id,
      hub_id,
      isSubmit = true
    ) {
      let notReadyToSubmit = false;
      let check_list_responses = [];
      const formData = new FormData();
      // let triggerIndex = -1;
      for (let i = 0; i < items.length; i++) {
        let resp = items[i];
        const {
          custom_data,
          single_value,
          multi_value,
          single_number_value,
          pass,
          signatureBase64,
          name
        } = resp;
        // validate requires

        if (custom_data?.type == "number" || custom_data?.type == "temp") {
          notReadyToSubmit = isNaN(single_number_value);
          if (
            (!isSubmit && single_number_value == "") ||
            single_number_value == undefined
          ) {
            notReadyToSubmit = false;
          }
        }
        if (notReadyToSubmit) {
          return {
            formData: null,
            notReadyToSubmit
          };
        }
        if (custom_data?.required && isSubmit) {
          if (
            custom_data.type == "option" ||
            custom_data.type == "radio" ||
            custom_data.type == "answer" ||
            custom_data.type == "yesno"
          ) {
            notReadyToSubmit = single_value == "" || single_value == undefined;
          }
          if (custom_data.type == "checkbox") {
            notReadyToSubmit =
              multi_value == undefined || multi_value.length == 0;
          }
          if (custom_data.type == "signature") {
            notReadyToSubmit =
              signatureBase64 == undefined ||
              signatureBase64 == "" ||
              signatureBase64 == null;
          }
          if (custom_data?.type == "check") {
            notReadyToSubmit = pass == undefined || pass == false;
          }
          if (custom_data?.type == "number" || custom_data?.type == "temp") {
            notReadyToSubmit =
              single_number_value == undefined || single_number_value == "";
            if (single_number_value == 0) {
              notReadyToSubmit = false;
            }
          }
        }

        if (notReadyToSubmit) {
          return {
            formData: null,
            notReadyToSubmit
          };
        }

        // if (custom_data.options && custom_data.options.length > 0) {
        //   triggerIndex = custom_data.options.findIndex(
        //     option => option.value == single_value
        //   );
        // }

        // if (triggerIndex > 0) {
        //   store.dispatch("checkList/sendTrigger", { resp, triggerIndex });
        // }

        if (resp?.id) {
          resp.checklist_item_id = JSON.parse(JSON.stringify(resp.id));
          delete resp["id"];
        }
        custom_data.question = name;
        check_list_responses.push(resp);
        if (resp.files) {
          Array.from(resp.files).forEach(file => {
            formData.append(`check_list_responses[${i}][files][]`, file);
          });
        }
        const tempResp = JSON.parse(JSON.stringify(resp));
        if (resp.signatureBase64) {
          const file = this.DataURIToBlob(resp.signatureBase64);
          formData.append(
            `check_list_responses[${i}][files][]`,
            file,
            "signature.png"
          );
          // delete resp["signatureBase64"];
          delete tempResp["signatureBase64"];
        }
        formData.append(
          `check_list_responses[${i}][data]`,
          JSON.stringify(tempResp)
        );
      }
      formData.append("check_list_id", check_list_id);
      formData.append("submit", true);
      formData.append("bike_id", bike_id ? bike_id : "");
      formData.append("hub_id", hub_id ? hub_id : "");

      return {
        formData,
        notReadyToSubmit
      };
    }
  }
};
