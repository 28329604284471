<template>
  <div class="fleetDetail flex">
    <div class="fixed top-0 min-w-full bg-gray-600 md:pt-6 pb-48 pt-12" />
    <div
      class="flex flex-wrap justify-between px-8 md:px-14 w-full"
      :class="{
        'md:flex-row-reverse': $route.params.id != 'new'
      }"
    >
      <div class="w-full lg:w-1/3 lg:pl-8" v-if="$route.params.id != 'new'">
        <div class="flex flex-row flex-wrap">
          <div
            class="relative shadow-lg rounded mb-5 break-words bg-white w-full py-5 px-4"
          >
            <input
              id="uploadFile"
              type="file"
              ref="uploadFile"
              @change="fileChange"
              accept="audio/*,video/*,image/*"
              hidden
            />
            <label
              class="bg-emerald-500 cursor-pointer text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              for="uploadFile"
            >
              {{ $t("Add Media") }}
            </label>
            <h3
              class="font-semibold mt-2 text-coolGray-500 text-center uppercase"
              v-if="bike.bike_images && bike.bike_images.length == 0"
            >
              {{ $t("No images uploaded yet") }}
            </h3>
            <div class="grid md:grid-cols-2 lg:grid-cols-3 py-3 gap-3">
              <div
                class="flex flex-col justify-between relative"
                v-for="image in bike.bike_images"
                :key="`${image.id}-${image.hash}`"
              >
                <img class="w-full" :src="image.path" />
                <i
                  class="fas fa-times-circle absolute -top-2 -right-2 bg-white rounded-full p-1 border cursor-pointer"
                  @click="removeUpload(image.id)"
                />
              </div>
            </div>
          </div>
        </div>
        <SlideUp>
          <div
            class="w-full m-auto mb-4 px-4 py-4 relative shadow-lg rounded bg-white xl:mb-5"
          >
            <h2 class="text-blueGray-400 text-sm mb-1 font-bold uppercase">
              {{ $t("booking status") }}
            </h2>
            <div
              v-if="checkedOut"
              class="font-semibold text-gray-500 ml-2 text-center"
            >
              <div class="text-xs">
                {{ $t("checked out to") }}
              </div>
              <div
                class="text-xl"
                v-if="checkedOutTo.user && checkedOutTo.user.name"
              >
                {{ checkedOutTo.user.name }}
              </div>
              <div class="text-xs">
                {{ formatTime(checkedOutTo.grabbed_at) }}
              </div>
              <!-- <div>
                {{ $t("from") }}: {{ formatTime(checkedOutTo.grabbed_at) }}
                <span
                  @click="checkoutReturn(checkedOutTo)"
                  class="bg-green-800 text-green-50 px-2 py-1 rounded"
                >
                  {{ $t("return now") }}
                </span>
              </div> -->
            </div>
            <div v-if="!checkedOut" class="text-center">
              <div class="text-3xl text-center font-semibold text-green-700">
                {{ $t("available") }}
              </div>
              <div
                class="cursor-pointer underline bg-gray-100 inline-block px-2"
                @click="currentTab = 'checkouts'"
              >
                {{ $t("go to checkout") }}
              </div>
              <!-- <el-select
                class="rounded-r-none"
                v-model="selectedUser"
                clearable
                placeholder="Select"
              >
                <el-option
                  v-for="user in activeUsers"
                  :key="user.id"
                  :label="user.name"
                  :value="user.id"
                >
                </el-option>
              </el-select>

              <span
                @click="checkoutGrab"
                class="bg-blue-600 text-blue-50 px-4 py-3 rounded rounded-l-none"
              >
                {{ $t("Checkout") }}
              </span> -->
            </div>
          </div>
        </SlideUp>
        <div
          class="w-full m-auto mb-4 px-4 py-4 relative shadow-lg rounded bg-white xl:mb-5"
        >
          <h2 class="text-blueGray-400 text-sm mb-1 font-bold uppercase">
            {{ $t("start checklist") }}
          </h2>

          <el-select
            @change="attachStartEndChecklist('start')"
            v-model="startChecklist"
            clearable
            class="w-full"
            placeholder="Select"
          >
            <el-option-group
              v-for="[status, options] in Object.entries(publishedCheckLists)"
              :key="status"
              :label="status"
            >
              <el-option
                v-for="checkList in options"
                :key="checkList.id"
                :label="checkList.name"
                :value="checkList.id"
              >
                <i
                  class="fas mr-1"
                  :class="{
                    'fa-check-circle text-teal-500': checkList.published,
                    'fa-times-circle text-rose-500': !checkList.published
                  }"
                />
                <span>{{ checkList.name }}</span>
              </el-option>
            </el-option-group>
          </el-select>
          <span
            class="text-rose-500 text-xs"
            v-if="
              bike.start_checklist && bike.start_checklist.published == false
            "
          >
            {{ bike.start_checklist.name }}
            {{ $t("is unpublished and not accesible in app") }}
          </span>
          <h2 class="text-blueGray-400 text-sm mb-1 font-bold uppercase mt-3">
            {{ $t("end checklist") }}
          </h2>

          <el-select
            @change="attachStartEndChecklist('end')"
            v-model="endChecklist"
            clearable
            class="w-full"
            placeholder="Select"
          >
            <el-option-group
              v-for="[status, options] in Object.entries(publishedCheckLists)"
              :key="status"
              :label="status"
            >
              <el-option
                v-for="checkList in options"
                :key="checkList.id"
                :label="checkList.name"
                :value="checkList.id"
              >
                <i
                  class="fas mr-1"
                  :class="{
                    'fa-check-circle text-teal-500': checkList.published,
                    'fa-times-circle text-rose-500': !checkList.published
                  }"
                />
                <span>{{ checkList.name }}</span>
              </el-option>
            </el-option-group>
          </el-select>
          <span
            class="text-rose-500 text-xs"
            v-if="bike.end_checklist && bike.end_checklist.published == false"
          >
            {{ bike.end_checklist.name }}
            {{ $t("is unpublished and not accesible in app") }}
          </span>
        </div>
        <div
          class="w-full m-auto mb-4 px-4 py-4 relative shadow-lg rounded flex justify-items-center items-center flex-col min-w-0 break-words bg-white xl:mb-5"
        >
          <QrCode
            v-if="bike.qr_id"
            :item="bike"
            :borderLess="true"
            :key="bike.qr_id"
          />
        </div>
        <div class="flex flex-row flex-wrap">
          <!-- <div
            class="w-1/2"
            v-for="({ name, value, unit, type }, index) in metrics"
            :key="`${index}${name}`"
          >
            <SlideUp>
              <div
                class="relative shadow-lg rounded-lg mb-5 flex flex-col min-w-0 break-words bg-white xl:mb-5"
                :class="{
                  'mr-2': index % 2 == 0,
                  'ml-2': index % 2 != 0
                }"
              >
                <div class="flex-auto p-4">
                  <div class="flex flex-wrap">
                    <div
                      class="relative w-full pr-4 max-w-full flex-grow flex-1"
                    >
                      <h5 class="text-blueGray-400 uppercase font-bold text-xs">
                        {{ $t(name) }}
                      </h5>
                      <p
                        class="font-bold text-4xl text-blueGray-700 text-center"
                        v-if="value"
                      >
                        <span v-if="type == 'time'">
                          {{ getTimeNoUnit(value) }}
                        </span>
                        <span v-else>
                          {{ value }}
                        </span>
                      </p>
                      <p
                        class="font-bold text-4xl text-blueGray-700 text-center"
                        v-else
                      >
                        {{ $t("-") }}
                      </p>
                      <p
                        v-if="value"
                        class="text-sm text-blueGray-600 text-right"
                      >
                        <span v-if="type == 'time'">
                          {{ getTimeUnit(value) }}
                        </span>
                        <span v-else>
                          {{ unit }}
                        </span>
                      </p>
                    </div>
                    <div class="relative w-auto pl-4 flex-initial"></div>
                  </div>
                </div>
              </div>
            </SlideUp>
          </div> -->
          <div class="w-full">
            <SlideUp>
              <div
                v-if="bike && bike.bike_damages && bike.bike_damages.length > 0"
                class="relative flex flex-col min-w-0 break-words bg-white rounded mb-6 xl:mb-0 shadow-lg p-8"
              >
                <apexchart
                  v-if="bike"
                  :key="bike.id"
                  type="donut"
                  height="193"
                  :options="chart1.chartOptions"
                  :series="chart1.series"
                />
              </div>

              <h3
                class="relative flex flex-col min-w-0 break-words bg-white rounded mb-6 xl:mb-0 shadow-lg p-8 text-center"
                v-else
              >
                <span class="text-2xl"> 🙌 </span>
                {{ $t("No Damages reported") }}
              </h3>
            </SlideUp>
          </div>
        </div>
      </div>
      <div class="w-full lg:w-2/3">
        <SlideUp>
          <Form
            @selectTab="selectTab"
            :form="formData"
            formName="Asset details"
            :options="options"
            :sectionItems="sectionItems"
            :currentTab="currentTab"
          >
            <template v-slot:mainAreaHeader>
              <div>
                <span class="text-gray-700 font-bold">
                  {{ bike.name }}
                </span>
                <!-- | {{ $t("hub") }}: {{ bike.hub.name }} | {{ $t("type") }}:
                {{ bike.type.name }} -->
              </div>
            </template>
            <template v-slot:mainArea v-if="currentTab != 'general'">
              <SlideUp class="w-full" v-if="currentTab == 'reports'">
                <ChecklistListing
                  :checklists="
                    bike.checklist_results && bike.checklist_results.length > 0
                      ? bike.checklist_results
                      : []
                  "
                />
              </SlideUp>
              <SlideUp
                class="w-full"
                v-if="$route.params.id != 'new' && currentTab == 'checkouts'"
              >
                <CheckoutListing :checkouts="bike.checkouts" />
              </SlideUp>
              <SlideUp
                class="w-full"
                v-if="$route.params.id != 'new' && currentTab == 'damages'"
              >
                <DamageReport :bike="bike" />
              </SlideUp>
            </template>
            <template v-slot:aftername v-if="$route.params.id != 'new'">
              <span
                @click="handleDelete"
                class="text-rose-500 border-b-4 border-rose-500 cursor-pointer text-xs"
              >
                {{ $t("delete") }}
              </span>
            </template>
            <template v-slot:header v-if="$route.params.id != 'new'">
              <div class="flex items-center">
                <el-dropdown trigger="click" @command="updateBikeStatus">
                  <span class="el-dropdown-link px-3 py-2 rounded">
                    <LabelDisplay v-if="bike.status" :label="bike.status" />
                    <span v-else>
                      {{ $t("no status yet") }}
                    </span>
                    <!-- <i class="el-icon-arrow-down el-icon--right"></i> -->
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      v-for="status in statuses"
                      :key="status.id"
                      class="flex justify-items-center items-center"
                      :command="status.id"
                    >
                      <div class="flex items-center justify-between w-full">
                        <span class="flex items-center">
                          <div
                            class="h-5 w-5 rounded-full mr-1"
                            :style="`background:${status.color}`"
                          />
                          {{ status.name }}
                        </span>
                        <i
                          class="fas fa-check text-green-500 ml-1"
                          v-if="bike.status && bike.status.name == status.name"
                        />
                      </div>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </template>
          </Form>
        </SlideUp>
      </div>
    </div>
  </div>
</template>

<script>
import SlideUp from "@/components/transitions/SlideUp.vue";
import Form from "@/components/Form.vue";
import QrCode from "@/components/QrCode.vue";
import LabelDisplay from "@/components/LabelDisplay.vue";
// import DamageReportForm from "@/components/DamageReportForm.vue";
import DamageReport from "@/components/DamageReport.vue";
import ChecklistListing from "@/components/checklist/ChecklistListing.vue";
import CheckoutListing from "@/components/CheckoutListing.vue";
import { mapState } from "vuex";
import formatting from "../mixins/formatting";
import customData from "../mixins/customData";
import { bindData } from "../helpers/form";

export default {
  name: "FleetDetail",
  components: {
    CheckoutListing,
    ChecklistListing,
    SlideUp,
    DamageReport,
    // DamageReportForm,
    Form,
    LabelDisplay,
    QrCode
  },
  mixins: [formatting, customData],
  data() {
    let formData = [
      {
        section: "Basic Information",
        dispatch:
          this.$route.params.id != "new"
            ? "bike/updateBike"
            : "bike/createBike",
        fields: [
          { key: "id", name: "id", type: "hidden", value: "" },
          {
            name: "name",
            key: "name",
            value: "",
            type: "text",
            width: "full"
          },
          {
            name: "hub",
            key: "hub_id",
            value: "",
            type: "selectByOptionProp",
            options: "hubs",
            optionLabelKey: "name"
          },
          {
            name: "type",
            key: "type_id",
            value: "",
            type: "selectByOptionProp",
            options: "bikeTypes",
            optionLabelKey: "name"
          },
          {
            name: "supplier",
            key: "supplier_id",
            value: "",
            type: "selectByOptionProp",
            options: "suppliers",
            optionLabelKey: "name"
          },
          {
            name: "status",
            key: "status_id",
            value: "",
            type: "selectByOptionProp",
            options: "statuses",
            optionLabelKey: "name"
          },
          {
            key: "can_self_book",
            value: null,
            name: "can self-book",
            type: "switch"
          },
          { key: "notes", value: "", name: "notes", type: "textarea" }
          // {
          //   name: "bike lock",
          //   key: "bike_lock",
          //   value: "",
          //   type: "select",
          //   options: bikeLocks,
          //   optionLabelKey: "code"
          // }
        ]
      }
      // {
      //   section: "Other",
      //   dispatch: "bike/updateBike",
      //   fields: [
      //     { key: "id", name: "id", type: "hidden", value: "" },
      //     { key: "notes", value: "", name: "notes", type: "textarea" }
      //   ]
      // }
    ];

    if (this.$route.params.id != "new") {
      formData[0].fields = formData[0].fields.filter(f => f.key != "status_id");
    }

    return {
      currentTab: "general",
      startChecklist: null,
      endChecklist: null,
      checkedOut: false,
      selectedUser: null,
      dialogVisible: false,
      dialogVisibleImage: "",
      createForm: false,
      openedDamageIndex: null,
      damageStatuses: [],
      formData
    };
  },
  computed: {
    ...mapState("bike", ["bike", "loading", "error"]),
    ...mapState("typeDefinitions", ["bike_damage_definitions"]),
    ...mapState("suppliers", ["suppliers"]),
    ...mapState("hubs", ["hubs"]),
    checkedOutTo() {
      return this.bike?.checkouts?.[0];
    },
    sectionItems() {
      return this.$route.params.id != "new"
        ? [
            { label: "checkouts" },
            { label: "reports" },
            { label: "damages" }
            // { label: "files" }
          ]
        : [];
    },
    publishedCheckLists() {
      return this.$store.state.checkLists.checkLists.reduce(
        (accu, checkList) => {
          const status = checkList.published
            ? this.$t("published")
            : this.$t("unpublished");
          if (!(status in accu)) accu[status] = [];
          accu[status].push(checkList);
          return accu;
        },
        {}
      );
    },
    activeUsers() {
      return this.$store.state.users.users.filter(u => u.status == "active");
    },
    options() {
      return {
        hubs: this.hubs,
        suppliers: this.suppliers,
        bikeTypes: this.$store.state.typeDefinitions.bike,
        statuses: this.statuses
      };
    },
    qrName() {
      return this.bike.name ? this.bike.name : this.bike.id;
    },
    // damageImages() {
    //   const res = this.bike_damages[this.openedDamageIndex].children
    //     .map(({ damage_images }) =>
    //       damage_images.length > 0 ? damage_images[0] : null
    //     )
    //     .filter(image => image != null);
    //     return res;
    // },
    chart1() {
      let labels = [];
      let series = [];
      if (this?.bike?.bike_damages) {
        const damageDistribution = this.bike.bike_damages.reduce(
          (accu, damage) => {
            const damage_type = damage?.type?.name
              ? damage?.type?.name
              : "No type";
            if (damage_type in accu) {
              accu[damage_type] = accu[damage_type] + 1;
            } else {
              accu[damage_type] = 1;
            }
            return accu;
          },
          {}
        );

        labels = Object.keys(damageDistribution);
        series = Object.values(damageDistribution);
      }

      return {
        series,
        chartOptions: {
          title: {
            text: "BIKE BREAKAGE STATUS",
            align: "left",
            margin: 10,
            offsetX: 0,
            offsetY: 0,
            floating: false,
            style: {
              fontSize: "14px",
              fontWeight: "600",
              fontFamily: undefined,
              color: "#94a3b8"
            }
          },
          chart: {
            type: "donut",
            fontFamily: "Montserrat, Arial, sans-serif",
            fontWeight: "thin"
          },
          labels,

          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200
                },
                legend: {
                  position: "bottom"
                }
              }
            }
          ]
        }
      };
    },
    statuses() {
      return this.$store?.state?.tags?.bike_status_labels
        ? this.$store.state.tags.bike_status_labels
        : [];
    },
    bike_damages() {
      const statusMap = this.damageStatuses.reduce((accu, item) => {
        accu[item.value] = item.show;
        return accu;
      }, {});
      return this.bike?.bike_damages
        ? this.bike?.bike_damages.filter(item => statusMap[item?.type?.id])
        : [];
    }
    // metrics() {
    //   return [
    //     {
    //       name: "Total shifts",
    //       value: this.bike?.shift_count,
    //       unit: "shifts"
    //     },
    //     {
    //       name: "Total usage",
    //       value: this.bike?.usages_in_min,
    //       type: "time",
    //       unit: "minutes"
    //     }
    //   ];
    // }
  },
  watch: {
    bike() {
      this.checkedOut = this.bike.checkouts
        ? this.bike.checkouts.find(checkout => !checkout.returned_at)
        : [];
      this.startChecklist = this.bike?.start_checklist_id;
      this.endChecklist = this.bike?.end_checklist_id;
      bindData(this.formData, this.bike);
      this.bindCustomData(this.bike);
    },
    bike_damage_definitions() {
      this.damageStatuses = this.bike_damage_definitions.map(item => ({
        label: item.name,
        value: item.id,
        show: true
      }));
    }
  },
  methods: {
    selectTab(item) {
      this.currentTab = item;
    },
    attachStartEndChecklist(clType) {
      const bike = {
        id: this.bike.id,
        action: "set_start_end_checklist"
      };
      if (clType == "start") {
        bike.start_checklist_id = this.startChecklist;
      }
      if (clType == "end") {
        bike.end_checklist_id = this.endChecklist;
      }
      this.$store.dispatch("bike/setStartEnd", { bike, cb: () => "" });
    },
    checkoutReturn(checkout) {
      this.$store.dispatch("checkouts/returnCheckout", {
        checkout,
        cb: () => this.$store.dispatch("bike/getBike", this.$route.params.id)
      });
    },
    checkoutGrab() {
      if (!this.selectedUser) return;
      this.$store.dispatch("checkouts/grabCheckout", {
        checkout: {
          action: "grabbing",
          bike_id: parseInt(this.$route.params.id),
          user_id: this.selectedUser
        },
        cb: () => this.$store.dispatch("bike/getBike", this.$route.params.id)
      });
    },
    handleDelete() {
      const deleteString = `${this.$t("Confirm delete asset")}: ${
        this.bike.name
      }`;
      this.$confirm(deleteString).then(() => {
        this.$store.dispatch("bike/deleteBike", {
          bike: this.bike
        });
      });
    },
    removeUpload(mediaId) {
      this.$store.dispatch("upload/removeBikeMedia", {
        id: this.bike.id,
        mediaId
      });
    },
    fileChange() {
      let formData = new FormData();
      formData.append("file", this.$refs.uploadFile.files[0]);
      formData.append(`action`, "bike_image");
      formData.append(`bike_id`, this.bike.id);
      this.$store.dispatch("upload/upload", {
        payload: formData,
        cb: () => this.$store.dispatch("bike/getBike", this.$route.params.id)
      });
    },
    damageImages(openedDamageIndex) {
      const res = this.bike_damages[openedDamageIndex].children
        .map(({ damage_images }) =>
          damage_images.length > 0 ? damage_images[0] : null
        )
        .filter(image => image != null);
      return res;
    },
    setDialog(src) {
      this.dialogVisible = true;
      this.dialogVisibleImage = src;
    },
    newDamageCreated() {
      this.createForm = false;
      this.openedDamageIndex = this?.bike_damages.length - 1;
    },
    updateBikeStatus(status_id) {
      this.$store.dispatch("bike/updateBike", {
        id: this.bike.id,
        status_id
      });
    },
    updateDamageStatus({ damage, status }) {
      const payload = {
        bike_damage: {
          bike_id: damage.bike_id,
          type_id: status,
          parent_id: damage.id,
          damage_type: damage.damage_type
        }
      };
      this.$store.dispatch("damages/createDamage", payload);
    },
    updateDamageRead(damage) {
      const tags = damage.tags.map(tag => tag.id);
      const payload = {
        bike_damage: {
          bike_id: damage.bike_id,
          // damage_status: damage.damage_status,
          type_id: damage.type_id,
          parent_id: damage.id,
          damage_type: damage.damage_type,
          tags,
          read: !damage.read
        }
      };
      this.$store.dispatch("damages/createDamage", payload);
    }
  },
  // mounted() {
  //   this.$store.dispatch("tags/getAllTags");
  //   if (this.$route.params.id != "new") {
  //     this.$store.dispatch("bike/getBike", this.$route.params.id);
  //   } else {
  //     this.$store.commit("bike/RESET_BIKE");
  //   }
  //   this.$store.dispatch("typeDefinitions/getAllBikeDamageDefinitions");
  //   this.$store.dispatch("typeDefinitions/getAllBikeDefinitions");
  // },
  created() {
    this.$store.dispatch("tags/getAllTags");
    if (this.$route.params.id != "new") {
      this.$store.dispatch("bike/getBikeWithCb", {
        id: this.$route.params.id,
        cb: () => {
          if (this.bike?.type?.schema?.columns) {
            this.setExtraSchema(this.bike?.type);
          }
        }
      });
    } else {
      this.$store.commit("bike/RESET_BIKE");
    }
    this.$store.dispatch("typeDefinitions/getAllBikeDamageDefinitions");
    this.$store.dispatch("typeDefinitions/getAllBikeDefinitions");
    // this.getSettings("fleet", "bike");
    this.$store.dispatch("users/getAllUsers");
    this.$store.dispatch("checkLists/getAllCheckLists");
  }
};
</script>

<style>
.el-dialog__body {
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  height: 100%;
}
.el-dialog.is-fullscreen {
  background: rgba(255, 255, 255, 0.7);
}
</style>
