var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-screen h-screen md:flex fixed top-0 left-0"},[_c('i',{staticClass:"fas fa-times fixed right-4 top-3 text-xl cursor-pointer",on:{"click":function($event){return _vm.$emit('close')}}}),_c('div',{staticClass:"w-full md:w-2/5 flex items-center px-5 bg-white"},[_c('div',[_c('h1',{staticClass:"text-3xl px-4 py-4 font-bold text-blueGray-500 rounded-t-lg"},[_vm._v(" "+_vm._s(_vm.$t("Booking asset"))+" ")]),_c('h2',{staticClass:"text-xl px-4 -mt-5 font-bold text-blueGray-400"},[_c('span',{staticClass:"text-gray-600"},[_vm._v(" "+_vm._s(_vm.$t("asset"))+": ")]),_c('span',[_vm._v(" "+_vm._s(_vm.bike.name)+" ")]),_vm._v(" / "),(_vm.bike.hub)?_c('span',{staticClass:"text-gray-600"},[_vm._v(" "+_vm._s(_vm.$t("hub"))+": ")]):_vm._e(),(_vm.bike.hub && _vm.bike.hub.name)?_c('span',[_vm._v(" "+_vm._s(_vm.bike.hub.name)+" ")]):_vm._e()])])]),_c('div',{staticClass:"h-screen w-full md:w-3/5 overflow-scroll"},[_c('div',{staticClass:"py-20 px-10 mb-36"},[_c('div',{staticClass:"px-4 mt-5"},[_c('div',{staticClass:"font-semibold",attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t("select user")))]),_c('el-select',{staticClass:"rounded-r-none",class:{
            'border-rose-500 border':
              _vm.showValidation == true && _vm.selectedUser == null
          },attrs:{"clearable":"","filterable":"","placeholder":"Select"},model:{value:(_vm.selectedUser),callback:function ($$v) {_vm.selectedUser=$$v},expression:"selectedUser"}},_vm._l((_vm.activeUsers),function(user){return _c('el-option',{key:user.id,attrs:{"label":user.name,"value":user.id}})}),1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showValidation == true && _vm.selectedUser == null),expression:"showValidation == true && selectedUser == null"}],staticClass:"text-rose-500 text-xs"},[_vm._v(" "+_vm._s(_vm.$t("you must select an user"))+" ")])],1),_c('div',{staticClass:"px-4 mt-5"},[_c('div',{staticClass:"font-semibold",attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t("booking type")))]),_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"border px-3 py-1 rounded border-gray-600 cursor-pointer",class:{
              'bg-gray-600 text-gray-50 rounded': _vm.isReservation
            },on:{"click":function($event){_vm.isReservation = true}}},[_vm._v(" "+_vm._s(_vm.$t("reservation"))+" ")]),_c('div',{staticClass:"border px-3 py-1 rounded border-gray-600 mx-3 cursor-pointer",class:{
              'bg-gray-600 text-gray-50 rounded': !_vm.isReservation,
              'cursor-not-allowed': _vm.isEdit
            }},[_c('div',{staticClass:"w-full",class:{
                'pointer-events-none': _vm.isEdit
              },on:{"click":function($event){_vm.isReservation = false}}},[_vm._v(" "+_vm._s(_vm.$t("instant booking"))+" ")])])])]),_c('div',{staticClass:"px-4 mt-5"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isReservation),expression:"!isReservation"}],staticClass:"font-semibold",attrs:{"for":""}},[_vm._v(" "+_vm._s(_vm.$t("set proposed returned date"))+" ("+_vm._s(_vm.$t("optional"))+") ")]),_c('el-date-picker',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isReservation),expression:"!isReservation"}],key:"endDate",attrs:{"type":"datetime","picker-options":_vm.pickerOptions,"placeholder":"Select date and time"},model:{value:(_vm.proposed_returned_at),callback:function ($$v) {_vm.proposed_returned_at=$$v},expression:"proposed_returned_at"}}),(_vm.isReservation)?_c('div',{staticClass:"font-semibold",attrs:{"for":""}},[_vm._v(" "+_vm._s(_vm.$t("reservation period"))+" ")]):_vm._e(),_c('el-date-picker',{directives:[{name:"show",rawName:"v-show",value:(_vm.isReservation),expression:"isReservation"}],key:"startendDate",attrs:{"type":"datetimerange","range-separator":"-","start-placeholder":"Start date","end-placeholder":"End date","picker-options":_vm.pickerOptions,"popper-class":"border-rose-500 border bg-green-400"},model:{value:(_vm.reservation),callback:function ($$v) {_vm.reservation=$$v},expression:"reservation"}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.showValidation == true &&
            _vm.isReservation == true &&
            (_vm.reservation == null || _vm.reservation.length == 0)
          ),expression:"\n            showValidation == true &&\n            isReservation == true &&\n            (reservation == null || reservation.length == 0)\n          "}],staticClass:"text-rose-500 text-xs"},[_vm._v(" "+_vm._s(_vm.$t("you must select reservation period"))+" ")])],1),_c('div',{staticClass:"px-4 mt-5"},[_c('div',{staticClass:"font-semibold",attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t("notes")))]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.notes),expression:"notes"}],staticClass:"w-full p-3 border px-5",attrs:{"rows":"5","placeholder":_vm.$t("optional")},domProps:{"value":(_vm.notes)},on:{"input":function($event){if($event.target.composing){ return; }_vm.notes=$event.target.value}}})]),(_vm.start_checklist_id)?_c('div',{staticClass:"px-4 mt-5"},[_c('div',{staticClass:"font-semibold",attrs:{"for":""}},[_vm._v(" "+_vm._s(_vm.$t("checklist"))+": "+_vm._s(_vm.start_checklist.name)+" ")]),_vm._l((_vm.checklist_items),function(item,index){return _c('PreviewComponent',{key:item.id,staticClass:"py-2",class:{
            'border-t': item.custom_data.type == 'heading' && index != 0,
            'pt-8': item.custom_data.type == 'heading'
          },attrs:{"notReadyToSubmit":_vm.notReadyToSubmit,"item":item}})})],2):_vm._e()]),_c('div',{staticClass:"dialog-footer px-4 py-6 flex justify-between fixed bottom-0 bg-blueGray-50 w-full md:w-3/5"},[_c('p',{staticClass:"text-rose-500 font-semibold px-6 absolute bottom-1 left-1"},[_vm._v(" "+_vm._s(_vm.$t("note: checkout is used to close out the checklist"))+" ")]),_c('div',[_c('button',{staticClass:"border-rose-500 text-rose-500 border rounded px-4 py-2 font-semibold mx-2",on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" "+_vm._s(_vm.$t("Cancel"))+" ")]),_c('button',{staticClass:"bg-green-600 rounded px-4 py-2 font-semibold mx-2 text-green-50",attrs:{"type":"primary"},on:{"click":function($event){return _vm.handleSubmit(true)}}},[_vm._v(" "+_vm._s(_vm.$t("checkout"))+" ")])]),_c('button',{staticClass:"bg-blue-500 rounded px-4 py-2 font-semibold mx-2 text-blue-50",attrs:{"type":"primary"},on:{"click":function($event){return _vm.handleSubmit(false)}}},[_vm._v(" "+_vm._s(_vm.$t("save"))+" ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }