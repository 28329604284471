<template>
  <div class="fleet w-full">
    <div class="px-4 md:px-10 mx-auto w-full relative -mt-11">
      <HelpBar :noDate="true">
        <template v-slot:right>
          <input
            id="uploadFile-checkout"
            type="file"
            ref="uploadFile"
            @change="fileChange"
            accept=".csv"
            hidden
          />
          <label
            class="bg-emerald-500 cursor-pointer text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
            for="uploadFile-checkout"
          >
            {{ $t("Import") }}
          </label>
        </template>
      </HelpBar>
      <div class="flex flex-wrap mt-4">
        <ListTable
          :tableName="tableName"
          :headers="headers"
          :tableData="tableData"
          :loading="$store.state.suppliers.loading"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ListTable from "@/components/ListTable.vue";
import userMixins from "@/mixins/userMixins";
import HelpBar from "../components/HelpBar.vue";
import axios from "../axios";
export default {
  name: "CheckoutSpace",
  props: {
    checkout: {
      type: Object,
      required: true
    }
  },
  mixins: [userMixins],
  components: {
    HelpBar,
    ListTable
  },
  data() {
    return {
      tab: "charts"
    };
  },
  computed: {
    spaceType() {
      return this.$store.state?.spaces?.space?.type
        ? this.$store.state?.spaces?.space?.type
        : "";
    },
    tableName() {
      return this.$store.state?.spaces?.space?.name
        ? this.$store.state?.spaces?.space?.name
        : "";
    },
    headers() {
      return this.$store.state?.spaces?.space?.meta_data?.columns
        ? this.$store.state?.spaces?.space?.meta_data?.columns
        : [];
    },
    tableData() {
      return this.$store.state?.spaces?.space?.rows
        ? this.$store.state?.spaces?.space?.rows
        : [];
    }
  },
  methods: {
    async fileChange() {
      let formData = new FormData();

      formData.append("file", this.$refs.uploadFile.files[0]);
      formData.append(`action`, "upload");
      formData.append(`type`, "checkout");
      formData.append(`checkout_id`, this.checkout.id);
      try {
        await axios.post(`/spaces`, formData);
        this.$notify({
          title: this.$t("Success"),
          message: this.$t("File uploaded"),
          type: "success"
        });
        this.getData();
      } catch (err) {
        this.$notify.error({
          title: this.$t("Error"),
          message: this.$t("Something went wrong please try uploading again")
        });
      }
    },
    getData() {
      this.$store.dispatch("spaces/getSpaceByType", {
        spaceType: "checkout",
        checkout_id: this.checkout.id
      });
    }
  },
  mounted() {
    this.getData();
  }
};
</script>
