<template>
  <div class="w-full m-auto mb-4 py-6 relative xl:mb-5">
    <h2
      class="text-blueGray-400 text-sm mb-1 font-bold uppercase flex justify-between items-center"
    >
      {{ $t("booking history") }}
      <span
        @click="handleEditReservation(null)"
        class="bg-blue-500 cursor-pointer text-white active:bg-emerald-600 font-bold uppercase text-xs px-2 py-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
      >
        {{ $t("process checkout") }}
      </span>
    </h2>
    <div
      class="pt-3 mb-2 border bg-white rounded"
      v-for="checkout in checkouts"
      :key="`${checkout.id}-checkout`"
    >
      <div class="flex items-center justify-between border-b pb-3 px-4">
        <div class="flex items-center">
          <ProfileImage :user="checkout.user" :bgNonWhite="true" />
          <div class="ml-2">
            {{ checkout.user.name }}
            <div class="text-xs">
              <span class="text-gray-400 font-semibold">
                {{ $t("period") }}:
              </span>
              <span v-if="checkout.grabbed_at">
                {{ formatTime(checkout.grabbed_at) }} -
                {{ formatTime(checkout.returned_at) }}
              </span>
              <span v-else>
                {{ formatTime(checkout.proposed_grabbed_at) }} -
                {{ formatTime(checkout.proposed_returned_at) }}
                <span class="underline text-blue-600 font-bold">
                  ({{ $t("reserved") }})
                </span>
              </span>
            </div>
          </div>
        </div>
        <div
          v-if="checkout.returned_at"
          class="border-teal-600 text-teal-600 border rounded px-4 py-1 flex items-center"
        >
          <i class="fas fa-check-circle text-teal-600 mr-1" />
          {{ $t("returned") }}
        </div>
        <div
          v-if="!checkout.returned_at && !checkout.grabbed_at"
          class="border-blue-600 text-blue-600 border rounded px-4 py-1 flex items-center cursor-pointer"
          @click="handleEditReservation(checkout)"
        >
          <i class="fas fa-clipboard-list text-blue-600 mr-1" />
          {{ $t("reserved") }}
        </div>
        <div
          v-else-if="!checkout.returned_at"
          class="border-rose-600 text-rose-600 border rounded px-4 py-1 flex items-center cursor-pointer"
          @click="checkoutReturn(checkout)"
        >
          <i class="fas fa-exchange-alt text-rose-600 mr-1" />
          {{ $t("return now") }}
        </div>
      </div>
      <div class="pl-7 flex justify-between">
        <div class="w-full border-r pb-3 pt-2">
          <div class="text-gray-400 font-bold text-xs">
            {{ $t("notes") }}:
            <span class="text-gray-700 font-normal">
              {{ checkout.notes }}
            </span>
          </div>
        </div>

        <div class="w-64 flex items-center font-semibold justify-around">
          <div
            v-if="checkout.start_checklist_result_id"
            class="cursor-pointer w-1/2 text-center h-full flex justify-center items-center"
            @click="viewResult(checkout, 'start')"
          >
            {{ $t("start") }}
          </div>
          <div
            v-if="checkout.end_checklist_result_id"
            class="cursor-pointer w-1/2 border-l text-center h-full flex justify-center items-center"
            @click="viewResult(checkout, 'end')"
          >
            {{ $t("end") }}
          </div>
          <div
            v-if="hasCheckoutImport"
            class="cursor-pointer w-1/2 border-l text-center h-full flex justify-center items-center"
            @click="viewImport(checkout)"
          >
            {{ $t("Import") }}
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      custom-class="w-full w-24"
      :fullscreen="true"
      :visible.sync="bookingDialog"
      aria-hidden="true"
      :show-close="false"
    >
      <CheckoutBooking
        @close="close"
        :checkout="checkout"
        :items="currentItem"
        :key="checkout && checkout.id ? checkout.id : 'new'"
      />
    </el-dialog>
    <el-dialog
      :visible.sync="showPreview"
      :fullscreen="true"
      :show-close="false"
    >
      <ViewCheckListResult
        @close="showPreview = false"
        :bike_id="checkout && checkout.bike_id ? checkout.bike_id : null"
        :items="currentItem"
        :title="checkListName"
        :checkout="checkout"
      />
    </el-dialog>
    <el-dialog
      :visible.sync="showImport"
      :fullscreen="true"
      :show-close="false"
    >
      <CheckoutSpace :checkout="checkout" v-if="showImport" />
    </el-dialog>
  </div>
</template>

<script>
import formatting from "../mixins/formatting";
import ProfileImage from "./ProfileImage.vue";
import CheckoutBooking from "./CheckoutBooking.vue";
import CheckoutSpace from "./CheckoutSpace.vue";
import fleetMixin from "../mixins/fleetMixin";
import ViewCheckListResult from "../components/checklist/ViewCheckListResult.vue";
export default {
  name: "CheckoutListing",
  mixins: [formatting, fleetMixin],
  components: {
    ProfileImage,
    CheckoutBooking,
    ViewCheckListResult,
    CheckoutSpace
  },
  props: {
    checkouts: {
      type: Array,
      default: () => {}
    }
  },
  computed: {
    hasCheckoutImport() {
      return this.$store.state.accounts.user.company?.custom_data
        ?.has_checkout_import == true
        ? true
        : false;
    }
  },
  data() {
    return {
      showImport: false,
      showPreview: false,
      currentItem: null,
      checkListName: "",
      selectedUser: null,
      notes: "",
      checkout: null,
      bookingDialog: false
    };
  },
  methods: {
    viewImport(checkout) {
      this.showImport = true;
      this.checkout = checkout;
    },
    handleEditReservation(checkout) {
      this.checkout = checkout;
      this.bookingDialog = true;
      let resultId = checkout?.start_checklist_result_id;
      this.currentItem = this.checklistByID[resultId]?.check_list_response;
    },
    viewResult(checkout, type) {
      this.checkout = checkout;
      let checklist_id =
        type == "start"
          ? checkout.start_checklist_result_id
          : checkout.end_checklist_result_id;

      this.currentItem = this.checklistByID[checklist_id].check_list_response;
      this.showPreview = true;
      this.checkListName = this.checklistByID[checklist_id].checklist_name;
    },
    close() {
      this.bookingDialog = false;
    },
    checkoutReturn(checkout) {
      this.$store.dispatch("checkouts/returnCheckout", {
        checkout,
        cb: () => this.$store.dispatch("bike/getBike", this.$route.params.id)
      });
    },
    checkoutGrab() {
      if (!this.selectedUser) return;
      this.$store.dispatch("checkouts/grabCheckout", {
        checkout: {
          action: "grabbing",
          bike_id: parseInt(this.$route.params.id),
          user_id: this.selectedUser,
          notes: this.notes
        },
        cb: () => this.$store.dispatch("bike/getBike", this.$route.params.id)
      });
    }
  },
  created() {
    this.$store.dispatch("bikes/getAllBikes", { noFilter: true });
  }
};
</script>
