<template>
  <div class="w-full m-auto mb-4 py-6 relative xl:mb-5">
    <h2 class="text-blueGray-400 text-sm mb-1 font-bold uppercase">
      {{ $t("checklist service reports") }}
    </h2>

    <div
      v-for="(checklist, index) in checklists"
      :key="`${checklist.id}-${checklist.inserted_at}-${index}`"
      @click="viewResult(checklist)"
      class="pt-3 mb-2 border bg-white rounded cursor-pointer"
    >
      <div class="flex items-center justify-between border-b pb-3 px-4">
        <div class="flex items-center">
          <ProfileImage :user="checklist.user" :bgNonWhite="true" />
          <div class="ml-2">
            {{ checklist.user.name }}
            <div class="text-xs">
              <span class="text-gray-400 font-semibold">
                {{ $t("period") }}:
              </span>
              {{ formatTime(checklist.inserted_at) }}
            </div>
          </div>
        </div>
        <div class="font-semibold">
          {{ checklist.checklist_name }}
        </div>
      </div>

      <!-- <div
          class="flex items-center border-gray-100 border-b py-3 justify-between px-2"
        >
          <div class="text-blueGray-800 uppercase text-xs font-semibold">
            <div class="font-normal text-blueGray-600">
              {{ checklist.checklist_name }}
            </div>
            {{ checklist.user.name }}
          </div>
          <div class="text-blueGray-600 text-xs">
            {{ formatTime(checklist.inserted_at) }}
          </div>
        </div> -->

      <!-- <div class="pl-7 flex justify-between">
        <div class="w-full border-r pb-3 pt-2">
          <div class="text-gray-700 font-bold">
            {{ checklist.checklist_name }}
          </div>
        </div>
      </div> -->
    </div>

    <el-dialog
      :visible.sync="showPreview"
      :fullscreen="true"
      :show-close="false"
    >
      <ViewCheckListResult
        @close="showPreview = false"
        :bike_id="current_bike_id"
        :items="currentItem"
        :title="checkListName"
      />
    </el-dialog>
  </div>
</template>

<script>
import ViewCheckListResult from "@/components/checklist/ViewCheckListResult.vue";
import formatting from "../../mixins/formatting";
import ProfileImage from "../ProfileImage.vue";
export default {
  name: "ChecklistListing",
  props: {
    checklists: {
      type: Array,
      required: true
    }
  },
  mixins: [formatting],
  components: { ViewCheckListResult, ProfileImage },
  data() {
    return {
      showPreview: false,
      current_bike_id: null,
      currentItem: null,
      checkListName: ""
    };
  },
  methods: {
    viewResult(checklist) {
      this.current_bike_id = checklist.bike_id;
      this.currentItem = checklist.check_list_response;
      this.showPreview = true;
      this.checkListName = checklist.checklist_name;
    }
  }
};
</script>

<style>
.el-dialog.is-fullscreen {
  background: hsl(225, 20%, 96%) !important;
}
</style>
