<template>
  <div class="w-screen h-screen md:flex fixed top-0 left-0">
    <i
      class="fas fa-times fixed right-4 top-3 text-xl cursor-pointer"
      @click="$emit('close')"
    />
    <div class="w-full md:w-2/5 flex items-center px-5 bg-white">
      <div>
        <h1 class="text-3xl px-4 py-4 font-bold text-blueGray-500 rounded-t-lg">
          {{ $t("Booking asset") }}
        </h1>
        <h2 class="text-xl px-4 -mt-5 font-bold text-blueGray-400">
          <span class="text-gray-600"> {{ $t("asset") }}: </span>
          <span>
            {{ bike.name }}
          </span>
          /
          <span v-if="bike.hub" class="text-gray-600"> {{ $t("hub") }}: </span>
          <span v-if="bike.hub && bike.hub.name">
            {{ bike.hub.name }}
          </span>
        </h2>
      </div>
    </div>
    <div class="h-screen w-full md:w-3/5 overflow-scroll">
      <div class="py-20 px-10 mb-36">
        <div class="px-4 mt-5">
          <div class="font-semibold" for="">{{ $t("select user") }}</div>
          <el-select
            class="rounded-r-none"
            v-model="selectedUser"
            :class="{
              'border-rose-500 border':
                showValidation == true && selectedUser == null
            }"
            clearable
            filterable
            placeholder="Select"
          >
            <el-option
              v-for="user in activeUsers"
              :key="user.id"
              :label="user.name"
              :value="user.id"
            >
            </el-option>
          </el-select>
          <div
            v-show="showValidation == true && selectedUser == null"
            class="text-rose-500 text-xs"
          >
            {{ $t("you must select an user") }}
          </div>
        </div>
        <div class="px-4 mt-5">
          <div class="font-semibold" for="">{{ $t("booking type") }}</div>
          <div class="flex items-center">
            <div
              class="border px-3 py-1 rounded border-gray-600 cursor-pointer"
              :class="{
                'bg-gray-600 text-gray-50 rounded': isReservation
              }"
              @click="isReservation = true"
            >
              {{ $t("reservation") }}
            </div>
            <div
              class="border px-3 py-1 rounded border-gray-600 mx-3 cursor-pointer"
              :class="{
                'bg-gray-600 text-gray-50 rounded': !isReservation,
                'cursor-not-allowed': isEdit
              }"
            >
              <div
                class="w-full"
                :class="{
                  'pointer-events-none': isEdit
                }"
                @click="isReservation = false"
              >
                {{ $t("instant booking") }}
              </div>
            </div>
          </div>
        </div>

        <div class="px-4 mt-5">
          <div v-show="!isReservation" class="font-semibold" for="">
            {{ $t("set proposed returned date") }} ({{ $t("optional") }})
          </div>
          <el-date-picker
            v-show="!isReservation"
            key="endDate"
            v-model="proposed_returned_at"
            type="datetime"
            :picker-options="pickerOptions"
            placeholder="Select date and time"
          />
          <div v-if="isReservation" class="font-semibold" for="">
            {{ $t("reservation period") }}
          </div>
          <el-date-picker
            v-show="isReservation"
            key="startendDate"
            v-model="reservation"
            type="datetimerange"
            range-separator="-"
            start-placeholder="Start date"
            end-placeholder="End date"
            :picker-options="pickerOptions"
            popper-class="border-rose-500 border bg-green-400"
          />
          <div
            v-show="
              showValidation == true &&
              isReservation == true &&
              (reservation == null || reservation.length == 0)
            "
            class="text-rose-500 text-xs"
          >
            {{ $t("you must select reservation period") }}
          </div>
        </div>

        <div class="px-4 mt-5">
          <div class="font-semibold" for="">{{ $t("notes") }}</div>
          <textarea
            v-model="notes"
            class="w-full p-3 border px-5"
            rows="5"
            :placeholder="$t(`optional`)"
          />
        </div>
        <div class="px-4 mt-5" v-if="start_checklist_id">
          <div class="font-semibold" for="">
            {{ $t("checklist") }}: {{ start_checklist.name }}
          </div>
          <PreviewComponent
            class="py-2"
            v-for="(item, index) in checklist_items"
            :notReadyToSubmit="notReadyToSubmit"
            :item="item"
            :key="item.id"
            :class="{
              'border-t': item.custom_data.type == 'heading' && index != 0,
              'pt-8': item.custom_data.type == 'heading'
            }"
          />
        </div>
      </div>
      <div
        class="dialog-footer px-4 py-6 flex justify-between fixed bottom-0 bg-blueGray-50 w-full md:w-3/5"
      >
        <p class="text-rose-500 font-semibold px-6 absolute bottom-1 left-1">
          {{ $t("note: checkout is used to close out the checklist") }}
        </p>
        <div>
          <button
            class="border-rose-500 text-rose-500 border rounded px-4 py-2 font-semibold mx-2"
            @click="$emit('close')"
          >
            {{ $t("Cancel") }}
          </button>
          <button
            class="bg-green-600 rounded px-4 py-2 font-semibold mx-2 text-green-50"
            type="primary"
            @click="handleSubmit(true)"
          >
            {{ $t("checkout") }}
          </button>
        </div>
        <button
          class="bg-blue-500 rounded px-4 py-2 font-semibold mx-2 text-blue-50"
          type="primary"
          @click="handleSubmit(false)"
        >
          {{ $t("save") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import utils from "../mixins/utils";
import PreviewComponent from "./checklist/PreviewComponent.vue";
import checklistSubmit from "../mixins/checklistSubmit";
export default {
  name: "CheckoutBooking",
  props: {
    checkout: {
      type: Object,
      default: () => {}
    },
    items: {
      type: Array,
      default: () => []
    }
  },
  components: { PreviewComponent },
  mixins: [utils, checklistSubmit],
  data() {
    return {
      showValidation: false,
      reservation: [],
      proposed_returned_at: null,
      isReservation: false,
      notReadyToSubmit: null,
      selectedUser: null,
      notes: "",
      pickerOptions: {
        disabledDate(date) {
          let now = moment();
          return moment(date).add(1, "days") <= now;
        }
      }
    };
  },
  watch: {
    checkout() {
      if (this.checkout?.id) {
        this.bindCheckoutData();
      }
    },
    start_checklist_id() {
      this.getChecklistItems();
    }
  },
  computed: {
    isEdit() {
      return this.checkout?.id ? true : false;
    },
    start_checklist() {
      return this.$store.state.bike?.bike?.start_checklist;
    },
    bike() {
      return this.$store.state.bike?.bike;
    },
    checklist_items() {
      return this.$store.state.checkListItems.checkListItems;
    },
    start_checklist_id() {
      return this.start_checklist?.id;
    },
    activeUsers() {
      return this.$store.state.users.users.filter(u => u.status == "active");
    }
  },
  methods: {
    bindCheckoutData() {
      let proposed_grabbed_at = this.checkout.proposed_grabbed_at;
      let proposed_returned_at = this.checkout.proposed_returned_at;
      this.reservation = [
        moment(proposed_grabbed_at),
        moment(proposed_returned_at)
      ];
      this.isReservation = true;
      this.selectedUser = this.checkout.user_id;
      this.notes = this.checkout.notes;
    },
    resetBooking() {
      this.$store.dispatch("bike/getBike", this.bike.id);
      this.$emit("close");
      this.reservation = [];
      this.proposed_returned_at = null;
      this.isReservation = false;
      this.notReadyToSubmit = null;
      this.selectedUser = null;
      this.notes = "";
      this.showValidation = false;
    },
    checkoutGrab() {
      if (!this.selectedUser) return;
      let checkout = {
        action: "grabbing",
        bike_id: this.bike.id,
        user_id: this.selectedUser,
        notes: this.notes,
        is_reservation: this.isReservation
      };
      if (this.isReservation) {
        if (this.reservation && this.reservation.length > 0) {
          checkout.proposed_grabbed_at = this.reservation[0].toISOString();
          checkout.proposed_returned_at = this.reservation[1].toISOString();
        }
      } else {
        if (this.proposed_returned_at) {
          checkout.proposed_returned_at = this.proposed_returned_at.toISOString();
        }
      }

      if (this.checkout?.id) {
        this.$store.dispatch("checkouts/updateGrabCheckout", {
          id: this.checkout.id,
          checkout: { checkout },
          cb: this.resetBooking
        });
      } else {
        this.$store.dispatch("checkouts/grabCheckout", {
          checkout: { checkout },
          cb: this.resetBooking
        });
      }
    },
    getChecklistItems() {
      if (this.start_checklist_id) {
        this.$store.dispatch("checkListItems/getAllCheckListItemsWithcb", {
          id: this.start_checklist_id,
          cb: () => {
            if (this.checkout?.id) {
              this.$store.commit("checkListItems/BIND_SAVED_DATA", {
                items: this.items,
                checklist_result_id: this.checkout.start_checklist_result_id
              });
            }
          }
        });
      }
    },
    handleSubmit(isSubmit = true) {
      // this.$emit("close");
      this.showValidation = true;
      if (!this.selectedUser) return;
      if (this.isReservation) {
        if (!(this.reservation && this.reservation.length > 0)) return;
      }
      if (this.start_checklist_id) {
        const { formData, notReadyToSubmit } = this.checkListResposnseFormData(
          this.checklist_items,
          this.start_checklist_id,
          this.bike.id,
          this.bike.hub_id,
          isSubmit
        );
        this.notReadyToSubmit = notReadyToSubmit;
        if (!notReadyToSubmit) {
          formData.append("checkout[action]", "grabbing");
          formData.append("checkout[bike_id]", this.bike.id);
          if (this.notes) {
            formData.append("checkout[notes]", this.notes);
          }
          if (!isSubmit) {
            formData.set("submit", false);
          }
          formData.set("checkout[user_id]", this.selectedUser);
          if (isSubmit == true) {
            formData.set("checkout[is_reservation]", false);
          } else {
            formData.set("checkout[is_reservation]", this.isReservation);
          }

          if (this.isReservation) {
            if (this.reservation && this.reservation.length > 0) {
              formData.set(
                "checkout[proposed_grabbed_at]",
                this.reservation[0].toISOString()
              );
              formData.set(
                "checkout[proposed_returned_at]",
                this.reservation[1].toISOString()
              );
            }
          } else {
            if (this.proposed_returned_at) {
              formData.set(
                "checkout[proposed_returned_at]",
                this.proposed_returned_at.toISOString()
              );
            }
          }

          if (this.checkout?.id) {
            this.$store.dispatch("checkouts/updateGrabCheckout", {
              id: this.checkout.id,
              checkout: formData,
              cb: this.resetBooking
            });
          } else {
            this.$store.dispatch("checkouts/grabCheckout", {
              checkout: formData,
              cb: this.resetBooking
            });
          }
        }
      } else {
        this.checkoutGrab();
      }
    }
  },
  created() {
    this.getChecklistItems();
    if (this.checkout?.id) {
      this.bindCheckoutData();
    }
  }
};
</script>
