<template>
  <div class="w-full m-auto mb-4 py-6 relative xl:mb-5">
    <h2
      class="text-blueGray-400 text-sm mb-1 font-bold uppercase flex justify-between items-center"
    >
      <span>{{ $t("Damage report") }}</span>
      <span
        v-if="bike_damages.length > 0"
        @click="startConvert"
        class="bg-blue-500 cursor-pointer text-white active:bg-emerald-600 font-bold uppercase text-xs px-2 py-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
      >
        {{ $t("convert to ticket") }}
      </span>
    </h2>
    <div class="flex mb-2 cursor-pointer uppercase text-xs">
      <div
        v-for="filter in filters"
        :key="`${filter.label}-filter`"
        class="px-2 rounded mr-2 text-gray-50"
        :class="`${
          selectedFilter == filter.label
            ? `bg-gray-700 text-gray-50`
            : `text-gray-700 border-gray-700 border bg-gray-50`
        }`"
        @click="selectedFilter = filter.label"
      >
        {{ filter.label }}
      </div>
    </div>
    <div v-if="bike_damages.length > 0">
      <DamageContent
        v-for="bike_damage in bike_damages"
        :key="`${bike_damage.id}-bikedamage`"
        :bike_damage="bike_damage"
        @selectDamage="selectDamage"
        :isSelectedDamage="formData[0].fields[5].value.includes(bike_damage.id)"
        @resolveDamage="resolveDamage"
      />
      <!-- :isSelectedDamage="
          bike_damage.id in selectedDamages
            ? selectedDamages[bike_damage.id]
            : false
        " -->
    </div>
    <h2 class="font-semibold text-center text-xl mt-6" v-else>
      {{ $t("there are no damages!") }}
    </h2>
    <el-dialog
      :visible.sync="dialogVisible"
      width="50%"
      custom-class="bg-blueGray-100-important"
    >
      <Form
        :hasShadow="false"
        :form="formData"
        :haveHeader="false"
        formName="new Ticket"
        :options="options"
        :lastPageName="'Ticket'"
      />
    </el-dialog>
    <el-dialog :title="$t('Confirm')" :visible.sync="labelDialog" width="350px">
      <div class="w-full">
        <div
          v-for="status in statuses"
          :key="status.id"
          class="flex justify-items-center items-center py-2 px-3 hover:bg-gray-100 cursor-pointer"
          :class="{
            'bg-gray-100': status_id == status.id
          }"
          @click="status_id = status.id"
        >
          <div class="flex items-center justify-between w-full">
            <span class="flex items-center">
              <div
                class="h-5 w-5 rounded-full mr-1"
                :style="`background:${status.color}`"
              />
              {{ status.name }}
            </span>
            <i
              class="fas fa-check text-green-500 ml-1"
              v-if="status_id == status.id"
            />
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <button
          class="border border-red-600 text-red-500 px-2 py-1 mr-2 rounded"
          @click="labelDialog = false"
        >
          {{ $t("Cancel") }}
        </button>
        <button
          class="bg-blue-500 text-blue-50 px-2 py-1 mr-2 rounded"
          type="primary"
          @click="updateBikeStatusAndDamageStatus"
        >
          {{ $t("mark as resolved") }}
        </button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from "vuex";
import ProfileImage from "./ProfileImage.vue";
import LabelDisplay from "./LabelDisplay.vue";
import formatting from "../mixins/formatting";
import PopoverWrapper from "./PopoverWrapper.vue";
import ticketform from "../mixins/ticketform";
import Form from "@/components/Form.vue";
const DamageContentDetail = {
  name: "DamageContentDetail",
  props: {
    activity: {
      type: Object,
      required: true
    }
  },
  mixins: [formatting],
  components: { ProfileImage },
  render() {
    const { action, changes, user, inserted_at } = this.activity;
    const { tags, notes } = changes;
    let content;
    let actionMade;
    if (action == "UPDATE_BIKE_DAMAGE_TAGS") {
      content = tags.map(tag => (
        <div>
          <LabelDisplay key={`${tag.id}${tag.name}`} label={tag} />
        </div>
      ));
      actionMade = (
        <div class="text-gray-500 ml-3">{this.$t("updated labels")}</div>
      );
    } else if (action == "UPDATE_BIKE_DAMAGE_NOTE") {
      content = <div class="border w-full px-3 py-2">{notes}</div>;
      actionMade = <div class="text-gray-500 ml-3">{this.$t("commented")}</div>;
    } else if (action == "UPDATE_BIKE_DAMAGE_READ") {
      actionMade = (
        <div class="text-gray-500 ml-3">{this.$t("marked as resolved")}</div>
      );
    } else if (action == "UPDATE_BIKE_DAMAGE_NOT_READ") {
      actionMade = (
        <div class="text-gray-500 ml-3">{this.$t("marked as unresolved")}</div>
      );
    } else if (action == "UPDATE_BIKE_DAMAGE_UPLOADS") {
      actionMade = (
        <div class="text-gray-500 ml-3">{this.$t("updated damage medias")}</div>
      );
    }
    return (
      <div class="flex flex-col my-3 px-7 border-b py-1">
        <div class="flex">
          <ProfileImage user={user} bgNonWhite={true} />
          <div class="ml-2">
            {user.name}
            <div class="text-xs">{this.formatTime(inserted_at)}</div>
          </div>
          {actionMade}
        </div>
        <div class="flex mt-2 ml-14">{content}</div>
      </div>
    );
  }
};

const DamageContent = {
  name: "DamageContent",
  props: {
    bike_damage: {
      type: Object,
      required: true
    },
    isSelectedDamage: {
      type: Boolean,
      required: true
    }
  },
  mixins: [formatting],
  components: {
    ProfileImage,
    DamageContentDetail,
    LabelDisplay,
    PopoverWrapper
  },
  data() {
    return {
      isOpen: false,
      tags: {},
      message: ""
    };
  },
  computed: {
    toggleResolve() {
      let classes = "border rounded px-2 mx-3 text-xs";
      const resolveString = this.bike_damage.read
        ? this.$t("mark as unresolved")
        : this.$t("mark as resolved");
      classes = this.bike_damage.read
        ? `${classes} text-rose-700 border-rose-600`
        : `${classes} text-teal-700 border-teal-600`;
      return (
        <span onClick={this.handleToggleResolve} class={classes}>
          {resolveString}
        </span>
      );
    },
    details() {
      if (this.isOpen == false) return <div />;
      return (
        <div class="pb-2">
          <div class="px-6 py-4">
            <input
              id="uploadFileDamage"
              class="uploadFile hidden"
              type="file"
              ref="uploadFile"
              onChange={this.fileChange}
              accept="image/*"
              capture="camera"
              multiple
            />
            <label
              class="bg-emerald-500 cursor-pointer text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              for="uploadFileDamage"
            >
              {this.$t("Add medias")}
            </label>
            {/*<input
            class=""
            type="file"
            ref="uploadFile"
            onChange={this.fileChange}
            accept="image/*"
            capture="camera"
            multiple
          />*/}
          </div>
          <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 p-4">
            {this.bike_damage?.damage_images &&
              this.bike_damage.damage_images.map(image => (
                <img class="" src={image.path} />
              ))}
          </div>
          <div class="px-6 mt-4">
            <textarea
              rows="5"
              class="w-full border px-3 py-2 focus:outline-none"
              vModel={this.message}
            />
            <button
              vOn:click={this.editDamages}
              class="rounded border px-3 py-1 bg-blue-500 text-blue-50"
            >
              {this.$t("comment")}
            </button>
          </div>
          {this.bike_damage.activities &&
            this.bike_damage.activities.map(activity => {
              return (
                <DamageContentDetail
                  key={`${activity.id}-childdamage`}
                  activity={activity}
                />
              );
            })}
        </div>
      );
    }
  },
  methods: {
    handleToggleResolve() {
      this.$emit("resolveDamage", this.bike_damage);
      // const { id, read, bike_id } = this.bike_damage;
      // const payload = { bike_damage: { id, bike_id, read: !read } };
      // this.$store.dispatch("damages/updateDamage", payload);
    },
    selectedDamageTags(tags) {
      const { id, bike_id } = this.bike_damage;
      const payload = {
        bike_damage: {
          id,
          bike_id,
          tags: Object.keys(tags).map(tag => parseInt(tag))
        }
      };
      this.$store.dispatch("damages/updateDamage", payload);
    },
    fileChange() {
      const payload = new FormData();
      if (this.$refs.uploadFile.files[0]) {
        payload.append(`bike_damage[file]`, this.$refs.uploadFile.files[0]);
        payload.append(`bike_damage[${"bike_id"}]`, this.bike_damage.bike_id);
        this.$store.dispatch("damages/updateDamageImages", {
          payload,
          id: this.bike_damage.id
        });
      }
    },
    editDamages() {
      // const { damage_type, notes, bike_id, bikeDamage, tags } = this;

      let bike_damage = {
        id: this.bike_damage.id,
        bike_id: this.bike_damage.bike_id,
        notes: this.message
      };
      let formData = { bike_damage };
      this.$store.dispatch("damages/updateDamage", formData);
      this.message = "";
    }
  },
  created() {
    this.tags = this.bike_damage.tags.reduce((accu, item) => {
      accu[item.id] = item;
      return accu;
    }, {});
  },
  render() {
    const klass =
      this.isSelectedDamage == true
        ? "fas fa-check-square mr-2"
        : "far fa-square mr-2";

    return (
      <div class="mb-2 border bg-white rounded cursor-pointer">
        <h1 class="font-bold text-gray-500 px-3 py-2 border-b flex justify-between items-center">
          <div onClick={() => this.$emit("selectDamage", this.bike_damage)}>
            <i class={klass} />
            {this.bike_damage?.type?.name && (
              <span>{this.bike_damage.type.name}</span>
            )}
            {this.bike_damage?.types &&
              this.bike_damage?.types.map(type => (
                <span class={"mr-2"} key={`type-id${type.id}`}>
                  {type.name}
                </span>
              ))}
          </div>
          <span>
            {this.toggleResolve}
            <PopoverWrapper
              items={this.$store.state.tags.bike_damage_tags}
              selectedItems={this.tags}
              hasLabelLink={true}
              onHandleItemChange={this.selectedDamageTags}
            />
          </span>
        </h1>
        <div class="flex justify-between items-center pr-4">
          <div class="flex pt-3 px-4">
            <ProfileImage user={this.bike_damage.user} bgNonWhite={true} />
            <div class="ml-2 w-full">
              {this.bike_damage.user.name}
              <div class="text-xs">
                <span class="text-gray-400 font-semibold mr-1">
                  {this.$t("reported at")}:
                </span>
                {this.formatTime(this.bike_damage.inserted_at)}
              </div>
            </div>
          </div>
          <div>
            {this.bike_damage?.tags &&
              this.bike_damage?.tags.map(tag => (
                <LabelDisplay key={`tag-id${tag.id}`} label={tag} />
              ))}
            <i
              class="fas fa-chevron-circle-down ml-2"
              onClick={() => (this.isOpen = !this.isOpen)}
            />
          </div>
        </div>
        <div class="pl-16 py-1">
          <span class="font-semibold mr-3">{this.$t("notes")}:</span>
          {this.bike_damage.notes}
        </div>
        <div class="py-3 border-t">
          <h2 class="font-semibold px-4 text-xs">
            {this.$t("ticket(s) referenced")}:
          </h2>
          <div class="border-b px-5 py-2">
            {this.bike_damage.tickets &&
              this.bike_damage.tickets.length == 0 && (
                <span class="text-xs">{this.$t("no references")}</span>
              )}
            {this.bike_damage.tickets &&
              this.bike_damage.tickets.map(ticket => (
                <div
                  class="text-xs font-normal ml-1 mb-3"
                  onClick={() =>
                    this.$router.push({
                      name: "Ticket-Detail",
                      params: { id: ticket.id }
                    })
                  }
                >
                  {ticket.is_closed && (
                    <span class="bg-rose-600 text-rose-50 px-2 py-1 mr-1">
                      {" "}
                      {this.$t("closed")}
                    </span>
                  )}
                  {!ticket.is_closed && (
                    <span class="bg-green-600 text-green-50 px-2 py-1 mr-1">
                      {" "}
                      {this.$t("open")}
                    </span>
                  )}
                  {ticket.subject}
                </div>
              ))}
          </div>
        </div>
        {this.details}
      </div>
    );
  }
};

export default {
  name: "DamageReport",
  props: {
    bike: {
      type: Object,
      require: true
    }
  },
  components: {
    DamageContent,
    Form
  },
  mixins: [ticketform],
  computed: {
    ...mapState("typeDefinitions", ["ticketDefinitions"]),
    ...mapState("suppliers", ["suppliers"]),
    ...mapState("hubs", ["hubs"]),
    ...mapState("bikes", ["bikes"]),
    ...mapState("users", ["users"]),
    statuses() {
      return this.$store?.state?.tags?.bike_status_labels
        ? this.$store.state.tags.bike_status_labels
        : [];
    },
    options() {
      const damages = this.$store.state.bike.bike.bike_damages;
      let damageOptions = damages
        .filter(({ read }) => !read)
        .map(item => {
          if (item.type) {
            return { id: item.id, name: item.type.name };
          }
          // if(item.types) {
          //   return { id: item.id, name: item.type.name }}
          // }
          return {};
        });
      return {
        ticketDefinitions: this.ticketDefinitions,
        suppliers: this.suppliers,
        hubs: this.hubs,
        bikes: this.bikes,
        users: this.users,
        damages: damageOptions
      };
    },
    bike_damages() {
      const damages = this.$store.state.bike.bike.bike_damages;
      if (this.selectedFilter == "unresolved") {
        return damages.filter(({ read }) => !read);
      } else if (this.selectedFilter == "resolved") {
        return damages.filter(({ read }) => read);
      }
      return damages;
    },
    selectedDamages() {
      return this.formData[0].fields[5].value;
    }
  },
  watch: {
    selectedDamages() {
      this.setSubject();
    }
  },
  data() {
    const { supplier_id, id, hub_id } = this.bike;
    return {
      status_id: null,
      bike_damage: {},
      labelDialog: false,
      dialogVisible: false,
      subject: "",
      selectedFilter: "unresolved",
      // selectedDamages: {},
      filters: [
        { label: "unresolved" },
        { label: "resolved" },
        { label: "all" }
      ],
      formData: [
        {
          section: "Ticket Information",
          dispatch: "tickets/createTicket",
          fields: [
            { key: "id", name: "id", type: "hidden", value: "" },
            {
              key: "subject",
              name: "title",
              type: "text",
              value: "",
              width: "full",
              required: true
            },
            {
              name: "description",
              key: "description",
              type: "textareaEditor",
              width: "full",
              value: ""
            },
            {
              key: "due_date",
              name: "due date",
              type: "datepicker",
              value: ""
            },
            {
              name: "types",
              key: "type_id",
              value: "",
              type: "selectByOptionProp",
              options: "ticketDefinitions",
              optionLabelKey: "name"
            },
            {
              name: "damages",
              key: "bike_damages",
              value: [],
              type: "multiselectByOptionProp",
              options: "damages",
              optionLabelKey: "name"
            },
            {
              name: "supplier",
              key: "supplier_id",
              value: supplier_id,
              type: "selectByOptionProp",
              options: "suppliers",
              optionLabelKey: "name",
              lock: true
            },
            {
              name: "recipients",
              key: "users",
              value: [],
              type: "multiselectByOptionProp",
              options: "users",
              optionLabelKey: "name"
            },
            {
              name: "hub",
              key: "hub_id",
              value: hub_id,
              type: "selectByOptionProp",
              options: "hubs",
              optionLabelKey: "name",
              lock: true
            },
            {
              name: "asset",
              key: "bike_id",
              value: id,
              type: "selectByOptionProp",
              options: "bikes",
              optionLabelKey: "name",
              lock: true
            }
          ]
        }
      ]
    };
  },
  methods: {
    updateBikeStatusAndDamageStatus() {
      this.$store.dispatch("bike/updateBike", {
        id: this.bike.id,
        status_id: this.status_id
      });
      const { id, read, bike_id } = this.bike_damage;
      const payload = { bike_damage: { id, bike_id, read: !read } };
      this.$store.dispatch("damages/updateDamage", payload);
      this.labelDialog = false;
    },
    resolveDamage(bike_damage) {
      this.labelDialog = true;
      this.bike_damage = bike_damage;
      this.status_id = this.bike.status.id;
      // const { id, read, bike_id } = this.bike_damage;
      // const payload = { bike_damage: { id, bike_id, read: !read } };
      // this.$store.dispatch("damages/updateDamage", payload);
    },

    setSubject() {
      this.formData[0].fields[1].value = this.options.damages.reduce(
        (accu, damage) => {
          if (this.selectedDamages.includes(damage.id)) {
            accu = `${accu} ${damage.name}`;
          }

          return accu;
        },
        `[${this.bike.name}]`
      );
    },
    startConvert() {
      this.dialogVisible = !this.dialogVisible;
      // this.formData[0].fields[5].value = Object.keys(
      //   this.selectedDamages
      // ).map(item => parseInt(item));
    },
    selectDamage(damage) {
      if (this.formData[0].fields[5].value.includes(damage.id)) {
        this.formData[0].fields[5].value = this.formData[0].fields[5].value.filter(
          val => val != damage.id
        );
      } else {
        this.formData[0].fields[5].value.push(damage.id);
      }
      this.setSubject();
      // if (damage.id in this.selectedDamages) {
      //   this.$delete(this.selectedDamages, damage.id);
      // } else {
      //   this.$set(this.selectedDamages, damage.id, true);
      // }
    },
    createTicket() {
      const { bike, subject } = this;
      this.$store.dispatch("tickets/createTicket", {
        subject,
        supplier_id: bike.supplier?.id,
        bikes: this.selectedBikesArray
      });
      this.subject = "";
    }
  }
};
</script>

<style>
.bg-blueGray-100-important {
  background-color: rgba(241, 245, 249, var(--tw-bg-opacity));
  border-radius: 0.4rem;
}
</style>
