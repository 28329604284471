import { mapState } from "vuex";

export default {
  name: "NewTicket",
  computed: {
    ...mapState("typeDefinitions", ["ticketDefinitions"]),
    ...mapState("suppliers", ["suppliers"]),
    ...mapState("hubs", ["hubs"]),
    ...mapState("bikes", ["bikes"]),
    ...mapState("users", ["users"]),
    options() {
      return {
        ticketDefinitions: this.ticketDefinitions,
        suppliers: this.suppliers,
        hubs: this.hubs,
        bikes: this.bikes,
        users: this.users
      };
    }
  },
  created() {
    this.$store.dispatch("typeDefinitions/getAllTicketDefinitions");
    this.$store.dispatch("suppliers/getAllSuppliers");
    this.$store.dispatch("users/getAllUsers");
    // this.$store.dispatch("bikes/getAllBikes");
    this.$store.dispatch("hubs/getAllHubs");
    this.$store.dispatch("bikes/getAllBikes", { noFilter: true });
  }
};
