export default {
  computed: {
    checklistByID() {
      const checklistResult = this.$store.state?.bike?.bike?.checklist_results;
      return checklistResult
        ? checklistResult.reduce((accu, result) => {
            accu[result.id] = result;
            return accu;
          }, {})
        : {};
    }
  }
};
